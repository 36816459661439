import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, Box } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {Link as RouterLink} from 'react-router-dom';
import CV from './assets/cv.pdf';

const useStyles = makeStyles(theme => ({
    links: {
      marginTop: theme.spacing(6),
      '& p': {
        marginBottom: theme.spacing(1),
      }
    }
}));

function Home(props) {

    const classes = useStyles(), globalCSS = props.globalCSS;

    return (
            <main className={globalCSS.layout}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={globalCSS.paper}>
                            <Typography component="h1" variant="h4" className={globalCSS.titleHome}>
                                Hi there!
                            </Typography>
                            <Typography component="p" variant="body1">
I’m Jack, a User Experience Designer with years of experience in various industries. I’m fascinated by AI and believe it will fundamentally change everything. 🤖
                            </Typography>

                            <Typography component="p" variant="body1">
Currently, I live and work in Germany, but I always enjoy traveling around the globe and talking to people from different cultures.
                            </Typography>
{/*
                            <Typography component="p" variant="body1" className={globalCSS.cv}>
                                <Link href={CV} target="_blank" rel="noreferrer">
                                    Download my CV (PDF, 348kb)
                                    <OpenInNewIcon className={globalCSS.openInNewIcon} />
                                </Link>
                            </Typography>
*/}
                            <Box className={classes.links}>
                            {/* 
                            <Typography component="p" variant="body1">
                                <Link to="/about" component={RouterLink}>
                                Learn more about me
                                </Link>
                            </Typography>
                            */}
                            <Typography component="p" variant="body1">
                                <Link to="/contact" component={RouterLink}>
                                Contact me
                                </Link>
                            </Typography>
                            <Typography component="p" variant="body1">
                                <Link href="https://thoughts.jackschwarz.com">
                                Read my thoughts on AI, design, and everything else.
                                </Link>
                            </Typography>
                            {/* 
                            <Typography component="p" variant="body1">
                                <Link to="/portfolio" component={RouterLink}>
                                Check my portfolio
                                </Link>
                            </Typography>
                            */}

                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
    );
}

export default Home;

