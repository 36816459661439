import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    ft: {
        clear: 'both',
        position: 'relative',
        height: '40px',
        marginTop: '-40px',
        textAlign: 'center',
        color: '#666',
        '& p': {
            fontSize: '.6rem'
        }
    }
}));

function Footer() {
    const classes = useStyles();

    return (
        <footer className={classes.ft}>
            <Typography component="p" variant="body2">
                &copy; 2024 www.jackschwarz.com All Rights Reserved.
            </Typography>

        </footer>
    );
}

export default Footer;
