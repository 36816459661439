import { Box, Breadcrumbs, CardMedia, Link, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

import TrierCover from "../../assets/videos-cover/trier-cover.jpg";
function WithLoveInTrier(props) {

    const 
        globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/videos">
                            Videos
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/videos/with-love-in-trier"
                            aria-current="page">
                            With Love in Trier
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={TrierCover}
                            title="With Love in Trier"/>
                        <Typography component="h1" variant="h4" className="title">
                            Feature Film: With Love in Trier
                        </Typography>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                There is a "Yearlong Project" in our major in Hochschule Trier. We could do
                                anything related to the theme "Media". So I decided to make a feature film.

                            </Typography>
                            <Typography component="p" variant="body1">
                                With Love in Trier tells a story between a Chinese student and a French girl.
                                Alina, a girl from Paris, took a visit to Trier and met Jack, a Chinese student,
                                at the freshmen party. They both thought the party was boring and went to the
                                beautiful inner city of Trier to take a tour. During the tour, they talked about lots
                                of different topics.

                            </Typography>
                            <Typography component="p" variant="body1">
                                I wrote, directed and produced this film. I was also the leading actor in the
                                film. And I did all the post-production on my own. Although there was almost no
                                budget, I still got the film done on time.
                            </Typography>

                            <Typography component="p" variant="body1">
                                The film lasts 70 minutes. It took us several months to finish it. I’ve gained
                                enormous experience from making this film. I’ve learned various things like how
                                to manage a crew, how to write a screenplay, how to draw storyboards, how to
                                edit the film, how to do color grading, etc.
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Watch the trailer
                            </Typography>
                            <Box className="player-wrapper">
                                <ReactPlayer
                                    className="player"
                                    url='https://youtu.be/HyHNX-2y1xw'
                                    controls={true}
                                    width="100%"
                                    height="100%"/>

                            </Box>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Watch the film
                            </Typography>

                            <Box className="player-wrapper">
                                <ReactPlayer
                                    className="player"
                                    url='https://youtu.be/cBNyYMzHyOw'
                                    controls={true}
                                    width="100%"
                                    height="100%"/>

                            </Box>
                        </Box>

                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default WithLoveInTrier;
