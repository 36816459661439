import { Box, Breadcrumbs, CardMedia, Link, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

import EinsetzenCover from "../../assets/videos-cover/einsetzen-cover.png";
function Einsetzen(props) {

    const 
        globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/videos">
                            Videos
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/videos/einsetzen"
                            aria-current="page">
                            Turn knowledge into action
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={EinsetzenCover}
                            title="Turn knowledge into action"/>
                        <Typography component="h1" variant="h4" className="title">
                            Video: Turn knowledge into action
                        </Typography>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                After an internal training, we had to make a presentation for our managers to
                                show how we could turn the knowledge we’ve learned into action to contribute more
                                to the company. We decided to make a video to tell some stories.
                            </Typography>

                            <Typography component="p" variant="body1">
                                Firstly we collected some existing problems in the company and discussed how to
                                solve them. Then we wrote a scenario of each problem and an after-scenario of
                                the better world after our engagement. The contrast should be impressive. We
                                also chose the comedy style since we didn’t want to offend the management.

                            </Typography>
                            <Typography component="p" variant="body1">
                                The video language is German.
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Watch the video
                            </Typography>
                            <Box className="player-wrapper">
                                <ReactPlayer
                                    className="player"
                                    url='https://youtu.be/ClK_jQ4oDOI'
                                    controls={true}
                                    width="100%"
                                    height="100%"/>

                            </Box>


                        </Box>

                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default Einsetzen;
