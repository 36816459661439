import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, CardMedia, Breadcrumbs, Paper, Box} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import ReactPlayer from 'react-player';

import GestaltCover from "../../assets/videos-cover/gestalt-cover.png";
function Gestalt(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/videos">
                            Videos
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/videos/gestalt"
                            aria-current="page">
                            The Gestalt Principles
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={GestaltCover}
                            title="The Gestalt Principles"/>
                        <Typography component="h1" variant="h4" className="title">
                            Motion Design: The Gestalt Principles
                        </Typography>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                The gestalt principles are a set of principles that refers to theories of visual
                                perception developed by German psychologists in the 1920s. These theories
                                attempt to explain how people organize visual elements into groups or unified
                                wholes. The principles include similarity, continuation, closure, proximity,
                                etc.
                            </Typography>

                            <Typography component="p" variant="body1">
                                I was pretty interested in Motion Design, so I decided to make a short video
                                with Adobe After Effects to explain these principles. I did the motion design
                                and my classmate Daniel Janßen composed the background music.
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Watch the video
                            </Typography>
                            <Box className="player-wrapper">
                                <ReactPlayer
                                    className="player"
                                    url='https://youtu.be/PHM8xNioBHE'
                                    controls={true}
                                    width="100%"
                                    height="100%"/>

                            </Box>

                        </Box>

                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default Gestalt;
