import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, CardMedia, Breadcrumbs, Paper, Box} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import GifPlayer from "react-gif-player";

import OtherCover from "../../assets/ux/others/cover.png";
import MultiSelector from "../../assets/ux/others/same-type-select.gif";
import RoleManager from "../../assets/ux/others/role-manager.gif";
import GlobalHD1 from "../../assets/ux/others/global-header-1.gif";
import GlobalHD2 from "../../assets/ux/others/global-header-2.gif";
import GlobalHD3 from "../../assets/ux/others/global-header-3.gif";
import InviteUser from "../../assets/ux/others/invite-user.gif";

function OtherUX(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/ux">
                            User Experience Design
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/ux/others"
                            aria-current="page">
                            Other Interaction Designs
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={OtherCover}
                            title="Other Interaction Designs"/>
                        <Typography component="h1" variant="h4" className="title">
                            Other Interaction Designs
                        </Typography>
                        <Box className="section">

                            <Typography component="p" variant="body1">
                                Because of the continuous improvement of our products, I often received small
                                requirements of redesigning some interactions or user flows. Although those
                                designs are small, they could demonstrate my prototyping skills and how I
                                analyze problems and find solutions.
                            </Typography>

                            <Typography component="p" variant="body1">
                                It’s not worthy to show those works separately, so I just put them all in this
                                page.
                            </Typography>

                        </Box>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Multiple selector
                            </Typography>
                            <Typography component="p" variant="body1">
                                User could upload pictures to multiple coffee machines at the same time, but
                                different machine type may require different picture format, so user could only
                                select the machines of the same type. That would need a new interaction design
                                for the selector.
                            </Typography>

                            <Typography component="p" variant="body1">
                                Firstly I designed the logic of the interaction:
                            </Typography>

                            <Typography component="p" variant="body1">
                                <ul>
                                    <li>
                                        1. User can view and filter all the machines on the list.
                                    </li>
                                    <li>
                                        2. If user selects one machine, then he could only see other machines of the
                                        selected type. At the same time, the “select all” checkbox appears.
                                    </li>
                                    <li>
                                        3. User can continue to select or unselect other machines.
                                    </li>
                                    <li>
                                        4. If no machine is selected, all the machines of different types will appear.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography component="p" variant="body1">

                                Here is the demo:
                                <GifPlayer gif={MultiSelector}/>

                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Role Manager
                            </Typography>
                            <Typography component="p" variant="body1">
                                As an administrator in the system, user can manage the roles and corresponding
                                privileges and assign the roles to other users. Based on the requirements I
                                built an interactive prototype with Axure RP.
                                <GifPlayer gif={RoleManager}/>
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Global Header
                            </Typography>
                            <Typography component="p" variant="body1">
                                There’re several sub-products on our platform, so we need a consistent global
                                header to enable user to navigate between those products. I did some research and
                                formulate some rules:
                            </Typography>

                            <Typography component="p" variant="body1">
                                <ul>
                                    <li>
1. Logo of the sub-product should always be on the left to indicate user “where am I”. 
                                    </li>
                                    <li>
2. Several menu items (such as notifications and my account) should be always in the header across all sub-products. 
                                    </li>
                                    <li>
3. Each sub-product could have their own menu items. 
                                    </li>
                                    <li>
                                4. Each sub-product could also have their own secondary navigation.
                                    </li>
                                </ul>
                            </Typography>

                            <Typography component="p" variant="body1">
                                Then I created a responsive interactive prototype with Axure RP.
                                <GifPlayer gif={GlobalHD1}/>
                                <GifPlayer gif={GlobalHD2}/>
                                <GifPlayer gif={GlobalHD3}/>

                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                User-Machine mapping
                            </Typography>
                            <Typography component="p" variant="body1">
                                On the platform, user can assign his coffee machines to other users. After
                                selecting the machines, user has to pick up the users in a tree view and select
                                roles. The tree view could be very complex and user could be easily lost and
                                forget to which users he already assigned the machine.
                            </Typography>

                            <Typography component="p" variant="body1">
                                To solve this problem, I created a list just under the tree view. Every
                                time user selects a user in the tree view, he could see the user in the list,
                                then he will have an overview and don’t have to remember everything.

                                The prototype was created with Axure RP.
                                <GifPlayer gif={InviteUser}/>
                            </Typography>

                        </Box>

                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default OtherUX;
