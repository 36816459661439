import React from 'react';
import {MuiThemeProvider, makeStyles, responsiveFontSizes} from '@material-ui/core/styles';
import {CssBaseline, createMuiTheme} from '@material-ui/core';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import Header from './shared/Header';
import Footer from './shared/Footer';
import Home from './Home';
import Portfolio from './Portfolio';
import About from './About';
import Contact from './Contact';
import UXList from './portfolio/UXList';
import VideoList from './portfolio/VideoList';
import EPayment from './portfolio/ux/EPayment';
import EPaymentVideo from './portfolio/videos/EPaymentVideo';
import RecipeEditor from './portfolio/ux/RecipeEditor';
import Monitoring from './portfolio/ux/Monitoring';
import Notification from './portfolio/ux/Notification';
import Gestalt from './portfolio/videos/Gestalt';
import CleanPrestolino from './portfolio/videos/CleanPrestolino';
import Conflict from './portfolio/videos/Conflict';
import Einsetzen from './portfolio/videos/Einsetzen';
import WithLoveInTrier from './portfolio/videos/WithLoveInTrier';
import ProductFinder from './portfolio/ux/ProductFinder';
import FoodSociety from './portfolio/ux/FoodSociety';
import OtherUX from './portfolio/ux/OtherUX';
import ScrollToTop from './ScrollToTop';

let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e64a19'
        },
        secondary: {
            main: '#42a5f5'
        }
    },
    typography: {
        fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',')
    }
});

theme = responsiveFontSizes(theme);

theme.typography.body1 = {
    fontSize: '1rem'
};

const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        margin: theme.spacing(8, 2, 12),
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            width: '56em',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [
            theme
                .breakpoints
                .down(430)
        ]: {
            marginTop: theme.spacing(14)
        },

        '& .MuiCardContent-root': {
            [
                theme
                    .breakpoints
                    .up('sm')
            ]: {
                minHeight: 120
            }
        }
    },
    paper: {
        margin: theme.spacing(4, 0),
        padding: theme.spacing(3),
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            padding: theme.spacing(4)
        },
        '& p': {
            marginBottom: theme.spacing(2),
            hyphens: 'auto'
        }
    },
    titleHome: {
        fontFamily: 'Fira Sans',
        fontWeight: 400,
        marginBottom: theme.spacing(3)
    },
    title: {
        fontFamily: 'Fira Sans',
        fontWeight: 400,
        margin: theme.spacing(4, 0, 3)
    },
    cv: {
        margin: theme.spacing(2, 0, 4, 0)
    },
    openInNewIcon: {
        fontSize: 16,
        verticalAlign: 'middle'
    },
    links: {
        marginTop: theme.spacing(6),
        '& p': {
            marginBottom: theme.spacing(1)
        }
    },
    cardMedia: {
        height: 140
    },
    comment: {
        marginBottom: theme.spacing(4),
    },
    breadcrumbs: {
        fontFamily: 'Fira Sans',
        fontWeight: 400,
        margin: theme.spacing(2, 0)
    },
    detailPage: {
        marginBottom: theme.spacing(4),
        padding: theme.spacing(3),
        '& .headImg': {
            height: 140,
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            height: 200,
        },
        },
        '& .title': {
            fontFamily: 'Fira Sans',
            fontWeight: 400,
            margin: theme.spacing(3, 0)
        },
        '& .section': {
            marginBottom: theme.spacing(4),
            '& p': {
                marginBottom: theme.spacing(2)
            }
        },

        '& .sub-title': {
            fontFamily: 'Fira Sans',
            fontWeight: 700,
            marginBottom: theme.spacing(1)
        },
        '& ul': {
            listStyleType: 'none',
            paddingLeft: theme.spacing(2)
        },
        '& .player-wrapper': {
            position: 'relative',
            paddingTop: '56.25%'
        },
        '& .player': {
            position: 'absolute',
            top: 0,
            left: 0
        },
    },
}));

const routes = [
    {
        path: "/",
        component: Home
    }, {
        path: "/about",
        component: About
    }, {
        path: "/portfolio",
        component: Portfolio
    }, {
        path: "/contact",
        component: Contact
    }, {
        path: "/portfolio/ux",
        component: UXList
    }, {
        path: "/portfolio/ux/e-payment",
        component: EPayment
    }, {
        path: "/portfolio/ux/recipe-editor",
        component: RecipeEditor
    }, {
        path: "/portfolio/ux/monitoring",
        component: Monitoring
    }, {
        path: "/portfolio/ux/notification",
        component: Notification
    }, {
        path: "/portfolio/ux/product-finder",
        component: ProductFinder
    }, {
        path: "/portfolio/ux/foodsociety",
        component: FoodSociety
    }, {
        path: "/portfolio/ux/others",
        component: OtherUX
    }, {
        path: "/portfolio/videos",
        component: VideoList
    }, {
        path: "/portfolio/videos/gestalt",
        component: Gestalt
    }, {
        path: "/portfolio/videos/conflict",
        component: Conflict
    }, {
        path: "/portfolio/videos/einsetzen",
        component: Einsetzen
    }, {
        path: "/portfolio/videos/e-payment",
        component: EPaymentVideo
    }, {
        path: "/portfolio/videos/clean-prestolino",
        component: CleanPrestolino
    }, {
        path: "/portfolio/videos/with-love-in-trier",
        component: WithLoveInTrier
    }
];

function App() {

    const globalCSS = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>

            <Router>
                <ScrollToTop>
                    <Header/>
                    <Switch>
                        {routes.map((route, i) => (
                            <Route
                                key={i}
                                exact
                                path={route.path}
                                render={(props) => <route.component {...props} globalCSS={globalCSS}/>}/>
                        ))}
                        <Route render={() => <Redirect to="/"/>}/>
                    </Switch>
                </ScrollToTop>
            </Router>
            <Footer/>
        </MuiThemeProvider>
    );
}

export default App;
