import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import {Toolbar, Link, Slide, useScrollTrigger} from '@material-ui/core';
import deepOrange from '@material-ui/core/colors/deepOrange';
import {Link as RouterLink, NavLink} from 'react-router-dom';

function HideOnScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger();

    return (
        <Slide direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    navLink: {
        padding: theme.spacing(0, 1),
        lineHeight: '64px',
        display: 'inline-block',
        color: 'inherit',
        textAlign: 'center',
        '&:hover': {
            color: deepOrange[800]
        },
        '&.active': {
            color: deepOrange[800],
            fontWeight: 700
        },
        '&.home': {
            paddingLeft: theme.spacing(0)
        },
        [
            theme
                .breakpoints
                .down(430)
        ]: {
            lineHeight: '56px'
        }
    },
    title: {
        flexGrow: 1,
        fontFamily: 'Fira Sans',
        fontWeight: 400,
        textTransform: 'uppercase'
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    sm: {
        [
            theme
                .breakpoints
                .down(430)
        ]: {
            display: 'none'
        }
    },
    mobile: {
        [
            theme
                .breakpoints
                .up(430)
        ]: {
            display: 'none'
        }
    }
}));

const navs = [
        /*
    {
        path: "/portfolio",
        title: "Portfolio"
    }, {
        path: "/about",
        title: "About"
    },
        */
    {
        path: "/contact",
        title: "Contact"
    }
];

function Header(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <HideOnScroll>
                <AppBar color="default" elevation={0} className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                            <Link to="/" component={RouterLink} color="inherit" underline="none">
                            Jack Schwarz
                            </Link>
                        </Typography>

                        {navs.map((nav, i) => (
                            <Typography key={i} variant="button" noWrap className={classes.sm}>
                                <Link
                                    to={nav.path}
                                    component={NavLink}
                                    className={classes.navLink}
                                    activeClassName="active"
                                    underline="none">
                                    {nav.title}
                                </Link>
                            </Typography>
                        ))}
                        <Typography variant="button" noWrap className={classes.sm}>
                            <Link
                                href="https://thoughts.jackschwarz.com"
                                className={classes.navLink}
                                activeClassName="active"
                                underline="none">
                                Thoughts
                            </Link>
                        </Typography>
                    </Toolbar>
                    <Toolbar className={classes.mobile}>
                        <Typography variant="button" noWrap>
                            <Link
                                exact
                                to="/"
                                component={NavLink}
                                className={`${classes.navLink} home`}
                                activeClassName="active"
                                underline="none">
                                Home
                            </Link>
                        </Typography>

                        {navs.map((nav, i) => (
                            <Typography key={i} variant="button" noWrap>
                                <Link
                                    to={nav.path}
                                    component={NavLink}
                                    className={classes.navLink}
                                    activeClassName="active"
                                    underline="none">
                                    {nav.title}
                                </Link>
                            </Typography>
                        ))}
                        <Typography variant="button" noWrap>
                            <Link
                                href="https://thoughts.jackschwarz.com"
                                className={classes.navLink}
                                activeClassName="active"
                                underline="none">
                                Thoughts
                            </Link>
                        </Typography>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
        </div>
    );
}

export default Header;
