import { Box, Breadcrumbs, CardMedia, Link, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ReactPlayer from 'react-player';
import ModalImage from "react-modal-image";

import FSCover from "../../assets/ux/foodsociety/cover.jpg";
import LogoS from "../../assets/ux/foodsociety/logo-small.png";
import Logo from "../../assets/ux/foodsociety/logo.png";
import MindMapS from "../../assets/ux/foodsociety/functions-overview-small.png";
import MindMap from "../../assets/ux/foodsociety/functions-overview.png";
import Persona from "../../assets/ux/foodsociety/persona.png";
import LoginS from "../../assets/ux/foodsociety/login-small.png";
import Login from "../../assets/ux/foodsociety/login.png";
import MapViewS from "../../assets/ux/foodsociety/map-small.png";
import MapView from "../../assets/ux/foodsociety/map.png";

function FoodSociety(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/ux">
                            User Experience Design
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/ux/foodsociety"
                            aria-current="page">
                            FoodSociety
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia className="headImg" image={FSCover} title="FoodSociety"/>
                        <Typography component="h1" variant="h4" className="title">
                            Project: FoodSociety
                        </Typography>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                FoodSociety is a project for my bachelor thesis at Hochschule Trier. I wrote the
                                bachelor thesis in German and the language of the User Interface is also German.
                                I designed the whole project on my own.

                            </Typography>
                            <Typography component="p" variant="body1">
                                Food waste is a huge global problem. According to FAO, roughly one-third of the
                                food produced in the world for human consumption every year — approximately 1.3
                                billion tons — gets lost or wasted. In developing countries, 40% of losses occur
                                at post-harvest and processing levels while in industrialized countries more
                                than 40% of losses happen at retail and consumer levels.

                            </Typography>
                            <Typography component="p" variant="body1">
                                What could we do to fight against food waste? Could we share the extra food
                                with others instead of throwing them away? That's why I designed FoodSociety.
                                FoodSociety is a mobile App which provides a platform for people who want to
                                share their food.
                            </Typography>

                            <Typography component="p" variant="body1">
                                While designing the app I used the methods of User Experience Design like
                                Persona, Scenario, and Service Journey Map. The app design is based on Material
                                Design which is a design language developed by Google in 2014. And the prototype
                                was made with InVision.
                            </Typography>

                        </Box>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Logo Design
                            </Typography>

                            <ModalImage small={LogoS} large={Logo} hideDownload={true} alt="Logo"/>
                        </Box>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Functions Overview
                            </Typography>

                            <ModalImage
                                small={MindMapS}
                                large={MindMap}
                                hideDownload={true}
                                alt="Functions Overview"/>
                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                One of the Personas
                            </Typography>

                            <ModalImage
                                small={Persona}
                                large={Persona}
                                hideDownload={true}
                                alt="Persona: Daniel"/>
                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Some screen designs
                            </Typography>

                            <ModalImage small={LoginS} large={Login} hideDownload={true} alt="Login"/>
                            <ModalImage
                                small={MapViewS}
                                large={MapView}
                                hideDownload={true}
                                alt="Map View"/>
                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Video demo
                            </Typography>
                            <Typography component="p" variant="body1">
                                I made this video with Origami Studio from Facebook.
                            </Typography>

                            <Box className="player-wrapper">
                                <ReactPlayer
                                    className="player"
                                    url='https://youtu.be/rZ1XNAuSoaA'
                                    controls={true}
                                    width="100%"
                                    height="100%"/>

                            </Box>
                        </Box>

                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default FoodSociety;
