import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, Box} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {Link as RouterLink} from 'react-router-dom';
import CV from './assets/cv.pdf';
import UXCS from './assets/ux-certificate-s.png';
import UXC from './assets/ux-certificate.png';
import ModalImage from "react-modal-image";

const useStyles = makeStyles(theme => ({
    links: {
        marginTop: theme.spacing(6),
        '& p': {
            marginBottom: theme.spacing(1)
        }
    }
}));

function About(props) {

    const classes = useStyles(),
        globalCSS = props.globalCSS;

    return (
        <main className={`${globalCSS.layout} about`}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h4" className={globalCSS.title}>
                        About
                    </Typography>
                    <Paper className={globalCSS.paper}>

                        <Typography component="p" variant="body1">
                            My name is Jack Schwarz. In the first 25 years of my life in China, I’ve
                            finished my study of Computer Science and Technology and worked as a developer
                            for 5 years. In 2013 I came to Germany and started learning German in a language
                            school Cologne. One year later I passed the DSH (German language examination for
                            university entrance) and got the chance to study Intermedia Design in Hochschule
                            Trier.
                        </Typography>

                        <Typography component="p" variant="body1">
                            During the 3 years study in Trier, I’ve learned lots of valuable skills
                            including User Experience Design, Sound Design, Typography, Storytelling, and
                            Filmmaking. All of them have been proved to be very helpful to my career of User
                            Experience Designer even though some skills couldn’t be applied to my job
                            directly. Besides that, I fell in love with Trier. I enjoyed the old city, the
                            theatre, the cinemas, and the beautiful Mosel river. I see Trier as my hometown
                            in Germany.
                        </Typography>

                        <Typography component="p" variant="body1">
                            After graduating in 2017, I started working as a User Experience Designer until now. User Experience Design is not only about creating beautiful interfaces but also includes user research, prototyping, and usability test. Although my primary tasks are user interface design and prototyping, I am always prepared to help other team members to do user research, workshops, and testings. My outputs are user interfaces and prototypes, but I share the same goal with product owners and developers, which is to ensure the end products have impressive user experience.
                        </Typography>

                        <Typography component="p" variant="body1">
                            Additionally, I attended the training courses from Nielsen Norman Group. I
                            learned a lot about Psychology, Information Theory, Interaction Design
                            Principles, and other helpful topics in 5 days. After the courses I earned the UX
                            Certificate with a Interaction Design Specialty.
                        </Typography>

                        <ModalImage
                            small={UXCS}
                            large={UXC}
                            hideDownload={true}
                            alt="UX Certificate - Nielsen Norman Group"/>

                        <Typography component="p" variant="caption">
                            Certificate ID: 1029891, Verify:
                            <Link href="https://www.nngroup.com/ux-certification/verify/">
                                https://www.nngroup.com/ux-certification/verify/</Link>
                        </Typography>

                        <Typography component="p" variant="body1">
                            In my spare time, I love traveling and exploring different cultures. Especially
                            I’m fascinated with the great cities in Europe such as Paris, Amsterdam,
                            Strasbourg, and Prague. I wish I could have chances to work in each city for
                            several years.
                        </Typography>

                        <Typography component="p" variant="body1">
                            I also enjoy learning new languages. I speak Chinese, English, and German. Now
                            I’m learning French and Japanese is already on my list.

                        </Typography>
                        <Typography component="p" variant="body1" className={globalCSS.cv}>
                            <Link href={CV} target="_blank" rel="noreferrer">
                                Download my CV (PDF, 348kb)
                                <OpenInNewIcon className={globalCSS.openInNewIcon}/>
                            </Link>
                        </Typography>
                        <Box className={classes.links}>
                            <Typography component="p" variant="body1">
                                <Link to="/portfolio" component={RouterLink}>
                                    Check my portfolio
                                </Link>
                            </Typography>
                            <Typography component="p" variant="body1">
                                <Link to="/contact" component={RouterLink}>
                                    Contact me
                                </Link>
                            </Typography>

                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </main>
    );
}

export default About;
