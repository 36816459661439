import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, Box} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    links: {
        marginTop: theme.spacing(6),
        '& p': {
            marginBottom: theme.spacing(1)
        }
    },
    label: {
        display: 'inline-block',
        width: theme.spacing(12),
        fontWeight: 700,
        [
            theme
                .breakpoints
                .down('xs')
        ]: {
            display: 'block'
        }
    }
}));

function Contact(props) {

    const classes = useStyles(),
        globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h4" className={globalCSS.title}>
                        Contact
                    </Typography>
                    <Paper className={globalCSS.paper}>
                        <Typography component="p" variant="body1">
                            <Box component="span" className={classes.label}>
                                Email:
                            </Box>
                            <Link href="mailto:jackschwarz2017@gmail.com">
                                jackschwarz2017@gmail.com
                            </Link>
                        </Typography>
                        <Typography component="p" variant="body1">
                            <Box component="span" className={classes.label}>
                                LinkedIn:
                            </Box>
                            <Link href="https://www.linkedin.com/in/jackschwarz2017/">
                                https://www.linkedin.com/in/jackschwarz2017/
                            </Link>
                        </Typography>
		{/*
                        <Typography component="p" variant="body1">
                            <Box component="span" className={classes.label}>
                                XING:
                            </Box>
                            <Link href="https://www.xing.com/profile/Jack_Schwarz2/">
                                https://www.xing.com/profile/Jack_Schwarz2/
                            </Link>
                        </Typography>
                        <Typography component="p" variant="body1">
                            <Box component="span" className={classes.label}>
                                Dribbble:
                            </Box>
                            <Link href="https://dribbble.com/jackschwarz">
                                https://dribbble.com/jackschwarz
                            </Link>
                        </Typography>
	    */}
                        <Typography component="p" variant="body1">
                            <Box component="span" className={classes.label}>
                                Thoughts:
                            </Box>
                            <Link href="https://thoughts.jackschwarz.com">
                                https://thoughts.jackschwarz.com
                            </Link>
                        </Typography>
                        <Box className={classes.links}>
                            {/* 
                            <Typography component="p" variant="body1">
                                <Link to="/about" component={RouterLink}>
                                    Learn more about me
                                </Link>
                            </Typography>
                            <Typography component="p" variant="body1">
                                <Link to="/portfolio" component={RouterLink}>
                                    Check my portfolio
                                </Link>
                            </Typography>
                            */}

                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </main>
    );
}

export default Contact;
