import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
    Link,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Breadcrumbs
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

import PaymentCover from "../assets/ux/payment/cover.png";
import RECover from "../assets/ux/recipe_editor/cover.png";
import MonitoringCover from "../assets/ux/monitoring/cover.png";
import NotiCover from "../assets/ux/notification/cover.png";
import PDCover from "../assets/ux/product_finder/cover.png";
import FSCover from "../assets/ux/foodsociety/cover.jpg";
import OtherCover from "../assets/ux/others/cover.png";

function UXList(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/ux"
                            aria-current="page">
                            User Experience Design
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h4" className={globalCSS.titleHome}>
                        User Experience Design
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/e-payment"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={PaymentCover}
                                    title="E-Payment Solution"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        E-Payment Solution
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Buy a cup of coffee with your phone.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/recipe-editor"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={RECover}
                                    title="Recipe Editor"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Recipe Editor
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Manage the beverage recipes in your coffee machine on the website.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/monitoring"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={MonitoringCover}
                                    title="Monitoring Redesign"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Monitoring Redesign
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Redesign the monitoring page, including layout and widgets.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/notification"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={NotiCover}
                                    title="Notification Settings"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Notification Settings
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Manage the Email and SMS notifications.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/product-finder"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={PDCover}
                                    title="Product Finder"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Product Finder
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Find the most suitable machines for your business.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/foodsociety"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia className={globalCSS.cardMedia} image={FSCover} title="FoodSociety"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        FoodSociety
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Share your food with others.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/others"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={OtherCover}
                                    title="Other Interaction Designs"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Other Interaction Designs
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        My other works.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

            </Grid>
        </main>
    );
}

export default UXList;
