import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, CardMedia, Breadcrumbs, Paper, Box} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import GifPlayer from "react-gif-player";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import NotiCover from "../../assets/ux/notification/cover.png";
import Demo from "../../assets/ux/notification/demo.gif";

function Notification(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/ux">
                            User Experience Design
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/ux/notification"
                            aria-current="page">
                            Notification Settings
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia className="headImg" image={NotiCover} title="Notification Settings"/>
                        <Typography component="h1" variant="h4" className="title">
                            Project: Notification Settings
                        </Typography>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                Sometimes user wants to receive Email or SMS notifications when something
                                happens to their coffee machines, so we wanted to design a page to let user set
                                up the notifications.
                            </Typography>

                        </Box>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Solution
                            </Typography>
                            <Typography component="p" variant="body1">
                                Because for such a requirement there’re already sophisticated solutions
                                available, it didn’t take us so much time to discuss. I quickly built an
                                interactive prototype with Axure RP.
                                <GifPlayer gif={Demo}/>
                            </Typography>
                            <Typography component="p" variant="body1">

                                Try the prototype yourself online:
                            </Typography>
                            <Typography component="p" variant="body1">
                                <Link href="https://wlzl25.axshare.com" target="_blank" rel="noreferrer">
                                    https://wlzl25.axshare.com
                                    <OpenInNewIcon className={globalCSS.openInNewIcon}/>
                                </Link>
                            </Typography>

                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default Notification;
