import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, Card, CardContent, CardActionArea, CardMedia} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

import PaymentCover from "./assets/ux/payment/cover.png";
import RECover from "./assets/ux/recipe_editor/cover.png";
import MonitoringCover from "./assets/ux/monitoring/cover.png";
import NotiCover from "./assets/ux/notification/cover.png";

import GestaltCover from "./assets/videos-cover/gestalt-cover.png";
import ConflictCover from "./assets/videos-cover/conflict-cover.png";
import EinsetzenCover from "./assets/videos-cover/einsetzen-cover.png";
import PaymentVideoCover from "./assets/ux/payment/video-cover.png";

const useStyles = makeStyles(theme => ({
    seeAll: {
        textAlign: 'right',
        marginBottom: theme.spacing(4)
    }
}));

function Portfolio(props) {

    const classes = useStyles(),
        globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h4" className={globalCSS.title}>
                        Portfolio
                    </Typography>
                    <Typography variant="body2" component="p" className={globalCSS.comment}>
                        <strong>The portfolio hasn't been updated since 2020.</strong> And there are some work, mostly UI designs, I can't show here publicly. Please <Link to="/contact" component={RouterLink}>contact me</Link> for more information if you are a recruiter or HR.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h6">
                        <Link to="/portfolio/ux" component={RouterLink} color="inherit">
                            User Experience Design
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/e-payment"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={PaymentCover}
                                    title="E-Payment Solution"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        E-Payment Solution
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Buy a cup of coffee with your phone.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/recipe-editor"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={RECover}
                                    title="Recipe Editor"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Recipe Editor
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Manage the beverage recipes in your coffee machine on the website.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/monitoring"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={MonitoringCover}
                                    title="Monitoring Redesign"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Monitoring Redesign
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Redesign the monitoring page, including layout and widgets.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/ux/notification"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={NotiCover}
                                    title="Notification Settings"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Notification Settings
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Manage the Email and SMS notifications.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} className={classes.seeAll}>
                    <Typography component="p" variant="body1">
                        <Link to="/portfolio/ux" component={RouterLink}>
                            All UX Design...
                        </Link>
                    </Typography>
                </Grid>

            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h6">
                        <Link to="/portfolio/videos" component={RouterLink} color="inherit">
                            Videos
                        </Link>
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/gestalt"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={GestaltCover}
                                    title="The Gestalt Principles"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        The Gestalt Principles
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Motion Design for the gestalt principles.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/conflict"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={ConflictCover}
                                    title="Conflict"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Conflict
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        A video about the definition of conflict.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/einsetzen"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={EinsetzenCover}
                                    title="Turn knowledge into action"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Turn knowledge into action
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        How could we apply what we've learned in the training?
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/e-payment"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={PaymentVideoCover}
                                    title="E-Payment Concept"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        E-Payment Concept
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        A video prototype for the E-Payment solution.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} className={classes.seeAll}>
                    <Typography component="p" variant="body1">
                        <Link to="/portfolio/videos" component={RouterLink}>
                            All Videos...
                        </Link>
                    </Typography>
                </Grid>

            </Grid>
        </main>
    );
}

export default Portfolio;
