import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, CardMedia, Breadcrumbs, Paper, Box} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import ReactPlayer from 'react-player';
import ModalImage from "react-modal-image";

import Cover from "../../assets/ux/payment/video-cover.png";
import StoryHeinzS from "../../assets/ux/payment/storyboard-heinz-s.png";
import StoryHeinz from "../../assets/ux/payment/storyboard-heinz.png";
import CardBoardS from "../../assets/ux/payment/machine-cardboard-s.jpg";
import CardBoard from "../../assets/ux/payment/machine-cardboard.jpg";
import XDS from "../../assets/ux/payment/xd-s.png";
import XD from "../../assets/ux/payment/xd.png";
import PrincipleS from "../../assets/ux/payment/principle-s.png";
import Principle from "../../assets/ux/payment/principle.png";

function EPaymentVideo(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/videos">
                            Videos
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/videos/e-payment"
                            aria-current="page">
                            E-Payment Concept
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={Cover}
                            title="E-Payment Concept"/>
                        <Typography component="h1" variant="h4" className="title">
                            Video: E-Payment Concept
                        </Typography>
                        <Box className="section">
                            <Typography component="p" variant="body1">
                                <Link to="/portfolio/ux/e-payment" component={RouterLink}>
                                    Read the first part of the project
                                </Link>
                            </Typography>
                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                As I mentioned in the first part of the project, after the productive workshop I
                                wanted to build a prototype based on one scenario we created. As you can see in
                                the storyboard, the process fo the E-Payment concept includes two parts: one
                                part for the smartphone, and one part for the coffee machine.
                            </Typography>
                            <ModalImage
                                small={StoryHeinzS}
                                large={StoryHeinz}
                                hideDownload={true}
                                alt="Storyboard: Heinz"/>
                            <Typography component="p" variant="caption">
                                Storyboard: Heinz
                            </Typography>
                            <Typography component="p" variant="body1">
                                It’s not too difficult to create a prototype for the smartphone, because
                                there’re many sophisticated prototyping tools. But for the coffee machine? I had
                                to find a workaround.
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Solve the problem
                            </Typography>

                            <Typography component="p" variant="body1">
                                The display of the coffee machine looks like an iPad. Maybe I could create a
                                prototype on the iPad and assume it’s a coffee machine? It’s a good solution for
                                me. However, how could I convince other stakeholders to “believe” the iPad is a
                                coffee machine? Just telling them is not a good way. “Show, not tell”, and I
                                knew selling the design is as much important as design itself.
                            </Typography>

                            <Typography component="p" variant="body1">
                                Suddenly I got an idea: I could build a cardboard coffee machine and attach the
                                iPad to it to create an illusion. My colleague from marketing team helped me
                                find an agency that makes cardboard models. Two weeks later we had a “coffee
                                machine”:
                            </Typography>
                            <ModalImage
                                small={CardBoardS}
                                large={CardBoard}
                                hideDownload={true}
                                alt="Cardboard Coffee Machine"/>
                            <Typography component="p" variant="caption">
                                Cardboard Coffee Machine
                            </Typography>

                            <Typography component="p" variant="body1">
                                Then I started building prototypes for iPad and iPhone. For iPad I built several
                                user interfaces with Adobe XD and made a click dummy:

                            </Typography>
                            <ModalImage
                                small={XDS}
                                large={XD}
                                hideDownload={true}
                                alt="UI design in Adobe XD"/>
                            <Typography component="p" variant="caption">
                                UI design in Adobe XD
                            </Typography>

                            <Typography component="p" variant="body1">
                                For iPhone I created an animated prototype with Principle for Mac:
                            </Typography>
                            <ModalImage
                                small={PrincipleS}
                                large={Principle}
                                hideDownload={true}
                                alt="App design in Principle"/>
                            <Typography component="p" variant="caption">
                                App design in Principle
                            </Typography>

                            <Typography component="p" variant="body1">
                                After having finished all the prototypes, I made this video for the whole concept with
                                several colleagues:

                            </Typography>

                            <Box className="player-wrapper">
                                <ReactPlayer
                                    className="player"
                                    url='https://youtu.be/fxHUzlbi7b0'
                                    controls={true}
                                    width="100%"
                                    height="100%"/>

                            </Box>

                        </Box>

                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default EPaymentVideo;
