import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, CardMedia, Breadcrumbs, Paper, Box} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import GifPlayer from "react-gif-player";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import PDCover from "../../assets/ux/product_finder/cover.png";
import Interview from "../../assets/ux/product_finder/interview.gif";
import Listview from "../../assets/ux/product_finder/list.gif";

function ProductFinder(props) {

    const 
        globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/ux">
                            User Experience Design
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/ux/product-finder"
                            aria-current="page">
                            Product Finder
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={PDCover}
                            title="Product Finder"/>
                        <Typography component="h1" variant="h4" className="title">
                            Project: Product Finder
                        </Typography>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                WMF has a list of professional coffee machines. The marketing team wanted to create
                                a product finder to facilitate the process of searching and selecting.
                            </Typography>

                        </Box>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Solution 1
                            </Typography>
                            <Typography component="p" variant="body1">
                                After researched and analyzed the solutions of product finders on other websites,
                                our first idea was to create an interview-like process: by answering some
                                questions like “which industry are you in?”, user will see the most suitable
                                machines at the end. We organized workshops with the colleagues from the marketing
                                team, sales team, and product team to find out all the metrics of the coffee
                                machines and which questions we should ask.
                            </Typography>

                            <Typography component="p" variant="body1">
                                Then I started to build a prototype to visualize the concept. At first, I tried
                                with Axure RP, but this time Axure RP couldn’t do the job well because the logic
                                of interaction was too complex. If I built all the logic in Axure RP, I had to
                                write lots of code, so why not build the prototype directly with code?
                            </Typography>

                            <Typography component="p" variant="body1">
                                After considered it for a while, I decided to build the prototype with the React
                                library created by Facebook. It took me several days to learn React, but it was
                                fun and easy to prototype with React.
                                <GifPlayer gif={Interview}/>
                            </Typography>

                            <Typography component="p" variant="body1">
                                As soon as I finished the prototype, I found a big problem in our process by
                                testing the prototype: User could frequently land on an empty result page if he
                                chose some “wrong” answers because we only have 10 coffee machine types! Maybe
                                the interview-like process is not a good idea?

                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Solution 2
                            </Typography>
                            <Typography component="p" variant="body1">
                                After discussed with the product owner, we both agreed that we needed a new
                                idea. I proposed a solution with the filter function: user could see all the
                                machines on the page and he could use filters to narrow down the results. For 10
                                coffee machine types it would work.
                            </Typography>
                            <Typography component="p" variant="body1">

                                Then I built a new prototype with React based on the new idea and the prototype
                                worked very well.
                                <GifPlayer gif={Listview}/>
                            </Typography>
                            <Typography component="p" variant="body1">
                                <Link href="/demos/product-finder/" target="_blank" rel="noreferrer">
                                Try the prototype yourself online
                                <OpenInNewIcon className={globalCSS.openInNewIcon}/>
                                </Link>
                            </Typography>

                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default ProductFinder;
