import {Box, Breadcrumbs, CardMedia, Link, Paper} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import ReactPlayer from 'react-player';

import ConflictCover from "../../assets/videos-cover/conflict-cover.png";
function Conflict(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/videos">
                            Videos
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/videos/conflict"
                            aria-current="page">
                            Conflict
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={ConflictCover}
                            title="Conflict"/>
                        <Typography component="h1" variant="h4" className="title">
                            Video: Conflict
                        </Typography>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                In an internal training, there was a course of Conflict Management. Before the
                                training began, our small team got the homework which is explaining the definition
                                of conflict. We decided to make a video.
                            </Typography>
                            <Typography component="p" variant="body1">

                                We wrote the script and photographed the footages together. Then I made the
                                video with Adobe After Effects.
                            </Typography>
                            <Typography component="p" variant="body1">
                                The video language is German.
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Watch the video
                            </Typography>
                            <Box className="player-wrapper">
                                <ReactPlayer
                                    className="player"
                                    url='https://youtu.be/DCA7QNV4h_E'
                                    controls={true}
                                    width="100%"
                                    height="100%"/>

                            </Box>

                        </Box>

                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default Conflict;
