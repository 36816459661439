import {Box, Breadcrumbs, CardMedia, Link, Paper} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import ReactPlayer from 'react-player';

import PresCover from "../../assets/videos-cover/prestolino-cover.png";
function CleanPrestolino(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/videos">
                            Videos
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/videos/clean-prestolino"
                            aria-current="page">
                            How to clean WMF Prestolino?
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={PresCover}
                            title="How to clean WMF Prestolino?"/>
                        <Typography component="h1" variant="h4" className="title">
                            Video: How to clean WMF Prestolino?
                        </Typography>

                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                In our office, there was a coffee machine, WMF Prestolino. We had to clean it
                                every day after work. The cleaning is quite complicated and it’s very difficult
                                to remember all the steps, therefore the experienced colleagues have to show how
                                to clean the machine many times when a new co-worker comes.

                            </Typography>
                            <Typography component="p" variant="body1">
                                To reduce the effort of teaching, I made a small video tutorial with
                                iPhone and Adobe After Effects.
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Watch the video
                            </Typography>
                            <Box className="player-wrapper">
                                <ReactPlayer
                                    className="player"
                                    url='https://youtu.be/f__g_QlmPGM'
                                    controls={true}
                                    width="100%"
                                    height="100%"/>

                            </Box>

                        </Box>

                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default CleanPrestolino;
