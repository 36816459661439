import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, CardMedia, Breadcrumbs, Paper, Box} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import ModalImage from "react-modal-image";

import Cover from "../../assets/ux/payment/cover.png";
import StoryTinaS from "../../assets/ux/payment/story-tina-s.jpg";
import StoryTina from "../../assets/ux/payment/story-tina.jpg";
import StoryCharlesS from "../../assets/ux/payment/story-charles-s.jpg";
import StoryCharles from "../../assets/ux/payment/story-charles.jpg";
import SketchCharlesS from "../../assets/ux/payment/sketch-charles-s.jpg";
import SketchCharles from "../../assets/ux/payment/sketch-charles.jpg";
import StoryHeinzS from "../../assets/ux/payment/storyboard-heinz-s.png";
import StoryHeinz from "../../assets/ux/payment/storyboard-heinz.png";

function EPayment(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/ux">
                            User Experience Design
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/ux/e-payment"
                            aria-current="page">
                            E-Payment Solution
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={Cover}
                            title="E-Payment Solution"/>
                        <Typography component="h1" variant="h4" className="title">
                            Project: E-Payment Solution for coffee machines
                        </Typography>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                Thanks to the smartphone and faster mobile internet, more and more people get
                                used to purchasing with their phones. As one of the best professional coffee
                                machine makers in the world, we (WMF) wanted to provide an E-Payment solution to
                                our coffee machines so that people can buy coffee with their phone directly.
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Workshop
                            </Typography>
                            <Typography component="p" variant="body1">
                                Although there are already many sophisticated solutions in the market, we still
                                wanted to do brainstorming and exploring all possible ideas. Therefore we
                                organized a one-day workshop with stakeholders.
                            </Typography>

                            <Typography component="p" variant="body1">
                                Firstly I introduced the method of design thinking and asked 3 questions to
                                trigger the ideas:
                            </Typography>

                            <Typography component="p" variant="body1">
                                <ul>
                                    <li>1. Who is the target user?</li>
                                    <li>2. In which scenarios would your user use our E-Payment solution? (Location,
                                        Building, etc.)</li>
                                    <li>3. Which tools/mediums does your user have? (Phone, Computer, etc.)</li>
                                </ul>
                            </Typography>
                            <Typography component="p" variant="body1">

                                Then we worked in small groups to create personas and stories for the target
                                users. After brainstorming and discussing, we generated 6 personas with
                                corresponding stories.
                            </Typography>

                            <ModalImage
                                small={StoryTinaS}
                                large={StoryTina}
                                hideDownload={true}
                                alt="User Story: Tina"/>
                            <Typography component="p" variant="caption">
                                User Story: Tina
                            </Typography>

                            <ModalImage
                                small={StoryCharlesS}
                                large={StoryCharles}
                                hideDownload={true}
                                alt="User Story: Charles"/>
                            <Typography component="p" variant="caption">
                                User Story: Charles
                            </Typography>

                            <Typography component="p" variant="body1">

                                Because some stories and scenarios are similar and we don’t have so many
                                resources, we did the dot-voting and selected 3 stories to work on. Each team
                                got a story and started sketching storyboards to visualize it.

                            </Typography>
                            <ModalImage
                                small={SketchCharlesS}
                                large={SketchCharles}
                                hideDownload={true}
                                alt="Sketch: Charles"/>
                            <Typography component="p" variant="caption">
                                Sketch: Charles
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Follow-up
                            </Typography>
                            <Typography component="p" variant="body1">
                                The workshop was very productive and every attendee was more clear about what we
                                are going to do. But the output was only messy flip charts, so I reorganized all
                                the information and also drew new storyboards with Photoshop to represent the
                                stories clearly.
                            </Typography>
                            <ModalImage
                                small={StoryHeinzS}
                                large={StoryHeinz}
                                hideDownload={true}
                                alt="Storyboard: Heinz"/>
                            <Typography component="p" variant="caption">
                                Storyboard: Heinz
                            </Typography>
                            <Typography component="p" variant="body1">

                                Now it’s time to create prototypes. But how? It’s complicated to prototype on
                                the coffee machines and we can’t test and improve the prototype quickly. Do we
                                have another option?
                            </Typography>

                            <Typography component="p" variant="body1">
                                <Link to="/portfolio/videos/e-payment" component={RouterLink}>
                                    Read the second part of the project and check the prototype
                                </Link>
                            </Typography>

                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default EPayment;
