import {Box, Breadcrumbs, CardMedia, Link, Paper} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import GifPlayer from "react-gif-player";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ModalImage from "react-modal-image";
import {Link as RouterLink} from 'react-router-dom';
import MonitoringCover from "../../assets/ux/monitoring/cover.png";
import Favorite from "../../assets/ux/monitoring/favorite.gif";
import Resize from "../../assets/ux/monitoring/resize.gif";
import ScreenshotS from "../../assets/ux/monitoring/screenshot-s.png";
import Screenshot from "../../assets/ux/monitoring/screenshot.png";
import Settings from "../../assets/ux/monitoring/settings.gif";

function Monitoring(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/ux">
                            User Experience Design
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/ux/monitoring"
                            aria-current="page">
                            Monitoring
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia
                            className="headImg"
                            image={MonitoringCover}
                            title="Monitoring Redesign"/>
                        <Typography component="h1" variant="h4" className="title">
                            Project: Monitoring Redesign
                        </Typography>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                On the online monitoring page, user can monitor the status of their coffee
                                machines, the live revenue, and other valuable statistics. Our team wanted to
                                redesign the current monitoring page to improve usability.
                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Problem analysis
                            </Typography>
                            <Typography component="p" variant="body1">
                                At first, I asked myself some question:
                            </Typography>
                            <Typography component="p" variant="body1">
                                <ul>
                                    <li>
                                        1. Which information does user want to see?
                                    </li>
                                    <li>
                                        2. Which information has higher priority?
                                    </li>
                                    <li>
                                        3. How could the information be represented?
                                    </li>
                                    <li>
                                        4. Will that be good if user could customize the page?
                                    </li>
                                    <li>
                                        5. …

                                    </li>
                                </ul>

                            </Typography>
                            <Typography component="p" variant="body1">

                                Then I discussed the questions along with the requirements with our product
                                owner to clarify the functions and priorities. At the same time, I analyzed the
                                current design and tried to find out the problems.
                            </Typography>
                            <ModalImage
                                small={ScreenshotS}
                                large={Screenshot}
                                hideDownload={true}
                                alt="Screenshot of current design"/>
                            <Typography component="p" variant="caption">
                                Screenshot of current design
                            </Typography>
                            <Typography component="p" variant="body1">

                                Above is a screenshot from the current monitoring page. We can see there’re lots
                                of white spaces which should be optimized to show more information. Filters on
                                the top are not fixed, so user must always scroll to top if he wants to change
                                one filter. User also can’t resort the charts.

                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Solution
                            </Typography>
                            <Typography component="p" variant="body1">
                                After brainstorming and problem analysis, I created some basic design rules:
                            </Typography>

                            <Typography component="p" variant="body1">
                                <ul>
                                    <li>
                                        1. Reduce the white spaces on the page.
                                    </li>
                                    <li>
                                        2. Every chart could be customized, including size, data, etc.
                                    </li>
                                    <li>
                                        3. User could resort the charts.
                                    </li>
                                    <li>
                                        4. User has the option to see the enlarged chart.
                                    </li>
                                    <li>
                                        5. User could bookmark the charts he wants to see on the top.
                                    </li>
                                    <li>
                                        6. Filters should be fixed as a sidebar so user could always reach it.
                                    </li>
                                    <li>
                                        7. Filters could be collapsed if user wants to concentrate on the charts.
                                    </li>
                                </ul>

                            </Typography>

                            <Typography component="p" variant="body1">
                                Based on those rules I built a prototype with Axure RP.
                            </Typography>

                            <Typography component="p" variant="body1">

                                <ul>
                                    <li>
                                        1. Customize the chart.
                                        <GifPlayer gif={Settings}/>
                                    </li>
                                    <li>
                                        2. See the enlarged chart.
                                        <GifPlayer gif={Resize}/>
                                    </li>
                                    <li>
                                        3. Bookmark charts.
                                        <GifPlayer gif={Favorite}/>
                                    </li>

                                </ul>
                            </Typography>

                            <Typography component="p" variant="body1">
                                Try the prototype yourself online:
                            </Typography>
                            <Typography component="p" variant="body1">
                                <Link href="https://gwug41.axshare.com" target="_blank" rel="noreferrer">
                                    https://gwug41.axshare.com
                                    <OpenInNewIcon className={globalCSS.openInNewIcon}/>
                                </Link>
                            </Typography>

                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default Monitoring;
