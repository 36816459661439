import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link, CardMedia, Breadcrumbs, Paper, Box} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import ModalImage from "react-modal-image";
import GifPlayer from "react-gif-player";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import RECover from "../../assets/ux/recipe_editor/cover.png";
import ScreenMainS from "../../assets/ux/recipe_editor/screen-main-s.png";
import ScreenMain from "../../assets/ux/recipe_editor/screen-main.png";
import ScreenDetailS from "../../assets/ux/recipe_editor/screen-detail-s.png";
import ScreenDetail from "../../assets/ux/recipe_editor/screen-detail.png";
import Gif1 from "../../assets/ux/recipe_editor/ixd-1.gif";
import Gif2 from "../../assets/ux/recipe_editor/ixd-2.gif";

function RecipeEditor(props) {

    const globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link color="inherit" component={RouterLink} to="/portfolio/ux">
                            User Experience Design
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/ux/recipe-editor"
                            aria-current="page">
                            Recipe Editor
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={globalCSS.detailPage}>
                        <CardMedia className="headImg" image={RECover} title="Recipe Editor"/>
                        <Typography component="h1" variant="h4" className="title">
                            Project: Recipe Editor
                        </Typography>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Introduction
                            </Typography>
                            <Typography component="p" variant="body1">
                                On the WMF professional coffee machine user could edit the recipes, such as
                                change the beverage’s name, remove some beverages, etc. The goal of this project
                                was to enable the customers to edit recipes online, and my job was to design the
                                Recipe Editor based on the user interface on the coffee machine.

                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Problem analysis
                            </Typography>

                            <Typography component="p" variant="body1">
                                The touch screen of the coffee machine and the web are two different mediums, so
                                it’s not a good idea to create the same user interface on the web. I examined
                                the Recipe Editor on the coffee machine carefully and tried to find out which
                                parts could be reused and which part must be redesigned.
                            </Typography>
                            <ModalImage
                                small={ScreenMainS}
                                large={ScreenMain}
                                hideDownload={true}
                                alt="Screen Design Example"/>
                            <Typography component="p" variant="caption">
                                Screen Design Example
                            </Typography>

                            <Typography component="p" variant="body1">

                                Take the screen above as an example, user could scroll the beverages
                                horizontally, but on the web it’s better to create a vertical scroll. And
                                there’re only ambiguous icons on the bottom which are actually buttons and they
                                don’t have any states. I should improve their usability on the web.
                            </Typography>

                            <Typography component="p" variant="body1">
                                Another example:
                            </Typography>
                            <ModalImage
                                small={ScreenDetailS}
                                large={ScreenDetail}
                                hideDownload={true}
                                alt="Screen Design Example"/>
                            <Typography component="p" variant="caption">
                                Screen Design Example
                            </Typography>

                            <Typography component="p" variant="body1">

                                User could click the squares in the upper grid to configure the ingredient, but
                                there’s no indicator to let user know the function.

                            </Typography>

                        </Box>
                        <Box className="section">
                            <Typography component="h2" variant="h5" className="sub-title">
                                Prototyping
                            </Typography>
                            <Typography component="p" variant="body1">
                                After having understood the design and functions of the Recipe Editor, I built
                                an interactive prototype with Azure RP for the web. Here are two demos:
                            </Typography>
                            <Typography component="p" variant="body1">
                                <ul>
                                    <li>

                                        1. The buttons will be enabled only after user selects the beverages.
                                        <GifPlayer gif={Gif1}/>
                                    </li>
                                    <li>
                                        2. User could configure the ingredient by clicking the buttons on the side.
                                        <GifPlayer gif={Gif2}/>

                                    </li>
                                </ul>

                            </Typography>

                            <Typography component="p" variant="body1">

                                Try the prototype yourself online:
                            </Typography>
                            <Typography component="p" variant="body1">
                                <Link href="https://rx3fcm.axshare.com" target="_blank" rel="noreferrer">
                                    https://rx3fcm.axshare.com
                                    <OpenInNewIcon className={globalCSS.openInNewIcon}/>
                                </Link>
                            </Typography>

                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </main>
    );
}

export default RecipeEditor;
