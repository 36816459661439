import { Breadcrumbs, Card, CardActionArea, CardContent, CardMedia, Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import GestaltCover from "../assets/videos-cover/gestalt-cover.png";
import ConflictCover from "../assets/videos-cover/conflict-cover.png";
import EinsetzenCover from "../assets/videos-cover/einsetzen-cover.png";
import PresCover from "../assets/videos-cover/prestolino-cover.png";
import PaymentVideoCover from "../assets/ux/payment/video-cover.png";
import TrierCover from "../assets/videos-cover/trier-cover.jpg";


function VideoList(props) {

    const 
        globalCSS = props.globalCSS;

    return (
        <main className={globalCSS.layout}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Breadcrumb" className={globalCSS.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to="/portfolio">
                            Portfolio
                        </Link>
                        <Link
                            component={RouterLink}
                            color="textPrimary"
                            to="/portfolio/videos"
                            aria-current="page">
                            Videos
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h4" className={globalCSS.titleHome}>
                        Videos
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/gestalt"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={GestaltCover}
                                    title="The Gestalt Principles"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        The Gestalt Principles
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Motion Design for the gestalt principles.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/conflict"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={ConflictCover}
                                    title="Conflict"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Conflict
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        A video about the definition of conflict.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/einsetzen"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={EinsetzenCover}
                                    title="Turn knowledge into action"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Turn knowledge into action
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        How could we apply what we've learned in the training?
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/e-payment"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={PaymentVideoCover}
                                    title="E-Payment Concept"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        E-Payment Concept
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        A video prototype for the E-Payment solution.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/clean-prestolino"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={PresCover}
                                    title="Clean WMF Prestolino"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Clean WMF Prestolino
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        A video tutorial for cleaning WMF Prestolino.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea>
                            <Link
                                to="/portfolio/videos/with-love-in-trier"
                                component={RouterLink}
                                color="inherit"
                                underline="none">
                                <CardMedia
                                    className={globalCSS.cardMedia}
                                    image={TrierCover}
                                    title="With Love in Trier"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        With Love in Trier
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        A feature film made in Trier.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>

            </Grid>
        </main>
    );
}

export default VideoList;
